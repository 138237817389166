import React from "react";
import styles from "./FormDropdown.module.scss";
import { ISelectOption } from "../../../models/select-option";
import cx from "classnames";
import { MdClear } from "react-icons/md";

interface Props {
  id: string;
  name: string;
  label: string;
  value: any;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  dropDownList: ISelectOption[];
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  error?: string | boolean;
  placeholder?: string;
  className?: string;
  required?: boolean;
  onUnselect?: () => void;
  dataTestE2E?: string;
}

const FormDropdown: React.FC<Props> = (props: Props) => {
  const { required = false, value, disabled = false } = props;

  const clearInputHandler = () => {
    if (props.onUnselect) {
      props.onUnselect();
    }
  };

  // TODO: Change Select Component
  return (
    <div className={cx(styles.FormDropdown, props.className ?? "")}>
      <label
        htmlFor={props.id}
        className={cx(
          required && styles.required,
          props.error && styles.labelError
        )}
      >
        {props.label}
      </label>
      <select
        className={cx(
          props.error && styles.selectError,
          !disabled && value === "" && styles.placeholder,
          props.value === "" && styles.selectDropdown
        )}
        id={props.id}
        name={props.name}
        onChange={props.onChange}
        disabled={disabled}
        value={value}
        data-test-e2e={props.dataTestE2E}
      >
        <option value="" disabled>
          {props.placeholder}
        </option>
        {props.dropDownList.map((option, index) => (
          <option key={index} value={option.value}>
            {option.title}
          </option>
        ))}
      </select>
      {props.onUnselect && props.value !== "" && (
        <MdClear className={styles.clearIcon} onClick={clearInputHandler} />
      )}
      {!disabled ? props.error && <p>{props.error}</p> : null}
    </div>
  );
};

export default FormDropdown;
