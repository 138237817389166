import { Slugs } from "./slugs.enum";

export const LocationDropdown = [
  {
    title: "Hawaii",
    value: Slugs.LOCATION_HAWAII,
  },
  {
    title: "Kauai",
    value: Slugs.LOCATION_KAUAI,
  },
  {
    title: "Maui",
    value: Slugs.LOCATION_MAUI,
  },
  {
    title: "San Francisco Bay Area",
    value: Slugs.LOCATION_SF_BAY_AREA,
  },
  {
    title: "Other",
    value: "other",
  },
];
