import React, { RefObject } from "react";
import styles from "./FindPackage.module.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import FormDropdown from "../../form-dropdown/FormDropdown";
import cx from "classnames";
import AFGCButton from "../../afgc-button/AFGCButton";
import { graphql, useStaticQuery, navigate } from "gatsby";
import { GqlListResponse } from "../../../../models/gql-list-response.model";
import { Portfolio } from "../../../../models/portfolio.model";
import { LocationDropdown } from "../../../../enums/location-dropdown";
import { getPackagesLink } from "../../../../utils/link.helper";
import { Slugs } from "../../../../enums/slugs.enum";

interface Props {
  reference?: RefObject<HTMLDivElement>;
  categoryInitialValue?: string;
  locationInitialValue?: string;
}

const formValidationSchema = Yup.object().shape({
  category: Yup.string().trim().required("Required"),
  location: Yup.string().trim().required("Required"),
});

const query = graphql`
  {
    portfolios: allMarkdownRemark(
      filter: {
        fields: { category: { eq: "portfolios" } }
        frontmatter: { link_to_home_page: { eq: true } }
      }
      sort: { order: ASC, fields: [fields___relativePath] }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }
  }
`;

const FindPackage: React.FC<Props> = (props: Props) => {
  const queryResult = useStaticQuery(query);

  const portfoliosRaw: GqlListResponse<Portfolio> = queryResult.portfolios;
  const portfolios = portfoliosRaw.edges;

  const categories = portfolios.map(({ node: portfolio }) => {
    return {
      value: portfolio.frontmatter.slug,
      title: portfolio.frontmatter.title,
    };
  });

  return (
    <section className={styles.container} ref={props.reference}>
      <div className={cx("column", styles.package)}>
        <h3>Find your photography package</h3>
        <p className={styles.package__desc}>
          You’ve found the right vacation destination... now choose the right
          package!
        </p>
        <Formik
          initialValues={{
            category: props.categoryInitialValue ?? "",
            location: props.locationInitialValue ?? "",
          }}
          validationSchema={formValidationSchema}
          onSubmit={async values => {
            navigate(getPackagesLink(values.category, values.location));
          }}
        >
          {formikProps => {
            let categoryValue: string = formikProps.values.category;
            let locations = LocationDropdown.filter(el => el.value !== "other");

            if (categoryValue.length !== 0) {
              switch (categoryValue) {
                case `${Slugs.PORTFOLIO_NEWBORN}`:
                case `${Slugs.PORTFOLIO_MATERNITY}`:
                  locations = [
                    { title: "Hawaii", value: `${Slugs.LOCATION_HAWAII}` },
                    { title: "Kauai", value: `${Slugs.LOCATION_KAUAI}` },
                  ];
                  break;
                case `${Slugs.PORTFOLIO_PORTRAIT}`:
                case `${Slugs.PORTFOLIO_FAMILY}`:
                  locations = [
                    { title: "Kauai", value: `${Slugs.LOCATION_KAUAI}` },
                  ];
                  break;
              }
            }

            return (
              <form
                autoComplete="off"
                className={styles.package__form}
                onSubmit={formikProps.handleSubmit}
                onReset={formikProps.handleReset}
              >
                <FormDropdown
                  label="Category"
                  id="category"
                  name="category"
                  placeholder="Select Category"
                  dropDownList={categories}
                  onChange={formikProps.handleChange}
                  value={formikProps.values.category}
                  onBlur={formikProps.handleBlur}
                  error={
                    formikProps.touched.category && formikProps.errors.category
                  }
                  className={styles.package__form__dropdown}
                  dataTestE2E="category"
                />
                <FormDropdown
                  label="Location"
                  id="location"
                  name="location"
                  placeholder="Select Location"
                  dropDownList={locations}
                  onChange={formikProps.handleChange}
                  value={formikProps.values.location}
                  onBlur={formikProps.handleBlur}
                  error={
                    formikProps.touched.location && formikProps.errors.location
                  }
                  className={styles.package__form__dropdown}
                  disabled={!formikProps.values.category}
                  dataTestE2E="location"
                />
                <AFGCButton
                  type="submit"
                  disabled={formikProps.isSubmitting}
                  dataTestE2E="find-package"
                >
                  Go to Packages
                </AFGCButton>
              </form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

export default FindPackage;
